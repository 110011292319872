import React, { useRef, useState, useCallback, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { debounce, mapValues, isEqual } from 'lodash'
import * as Yup from 'yup'

// core
import { useGlobal } from '_core/hooks/useGlobal'
import { withLang } from '_core/hocs/withLang'
import { withCatch } from '_core/hocs/withCatch'
import { getErrorCode } from '_core/utils/getErrorCode'
import { InputProgress } from '_core/components/InputProgress'


const VALIDATION_DEBOUNCE_DELAY = 250

const RawFormValidator = props => {
  const {
    render,
    Component,
    method,
    fields,
    hiddenFields,
    setCanSubmit,
    setHasTouched,
    touchOnInit,
    checkIfChanged,
    progressProps,
    renderProgress,
    ProgressComponent,
    getFirstLangString,
  } = props

  const {
    request: { queue },
    notify: { enqueueSnackbar },
    config: { API_METHOD },
  } = useGlobal()

  const values = mapValues(fields, (field) => field.value)
  const shapes = mapValues(fields, (field) => field.shape)
  const needCheck = useRef(touchOnInit)
  const initialValues = useRef(values)
  const restoreCursor = useRef()

  useLayoutEffect(() => {
    if (typeof restoreCursor.current === 'function') {
      restoreCursor.current()
      restoreCursor.current = null
    }
  }, [values])

  const serverCheckList = mapValues(fields, field =>
    field.serverCheck ? true : undefined
  )

  const [errors, setErrors] = useState({})
  const [touched, setTouched] = useState(
    touchOnInit ? mapValues(fields, () => true) : {}
  )
  const [checking, setChecking] = useState(
    touchOnInit ? mapValues(fields, () => true) : {}
  )

  const hasTouched = Object.keys(touched).length > 0
  let canSubmit =
    hasTouched &&
    Object.keys(checking).length === 0 &&
    Object.keys(errors).length === 0

  if (checkIfChanged) {
    canSubmit = canSubmit && !isEqual(initialValues.current, values)
  }

  typeof setCanSubmit === 'function' && setCanSubmit(canSubmit)
  typeof setHasTouched === 'function' && setHasTouched(hasTouched)

  const getLocalErrors = () => {
    const errors = {};
    
    try {
      Yup.object(shapes).validateSync(values, { abortEarly: false });
    } catch (e) {
      e.inner.forEach((item) => errors[item.path] || (errors[item.path] = item.message));
    }
    
    return errors;
  }

  const getRemoteErrors = useCallback(
    debounce(async ({ newValues, checkList, localErrors }) => {
      try {
        const result = await queue(API_METHOD.FORM_CHECK, {
          form: method,
          ...hiddenFields,
          ...newValues,
        })

        const remoteErrors = Object.keys(result.errors).reduce((res, key) => {
          res[key] = result.errors[key].code
          return res
        }, {})

        // update errors for remotely validated fields
        setErrors(err => {
          const newErr = Object.keys(fields).reduce((res, key) => {
            if (checkList[key] == null) {
              // copy old errors for locally-only validated fields
              err[key] != null && (res[key] = err[key])
            } else {
              if (remoteErrors[key] != null) {
                // set new remote errors for remotely validated fields
                res[key] = remoteErrors[key]
              } else if (localErrors[key] != null) {
                // restore old local errors for remotely validated fields
                res[key] = localErrors[key]
              } else {
                // remove old errors for remotely validated fields
                delete res[key]
              }
            }
            return res
          }, {})
          return newErr
        })

        // remove remotely validated fields from check-list
        setChecking(val => {
          const newChecking = Object.keys(val).reduce((res, key) => {
            checkList[key] == null && (res[key] = val[key])
            return res
          }, {})
          return newChecking
        })
      } catch (error) {
        process.env.NODE_ENV !== 'production' &&
          enqueueSnackbar(error.message, { variant: 'error' })
      }
    }, VALIDATION_DEBOUNCE_DELAY),
    [method, hiddenFields]
  )

  const validate = async () => {
    const localErrors = getLocalErrors()

    // update errors for locally-only validated fields
    setErrors(err => {
      const newErr = Object.keys(fields).reduce((res, key) => {
        if (serverCheckList[key] != null) {
          // copy old errors for remotely validated fields
          err[key] != null && (res[key] = err[key])
        } else {
          if (localErrors[key] != null) {
            // set new errors for locally-only validated fields
            res[key] = localErrors[key]
          } else {
            // remove old errors for locally-only validated fields
            delete res[key]
          }
        }
        return res
      }, {})
      return newErr
    })

    // get fields for remote check
    const checkList = Object.keys(serverCheckList).reduce((res, key) => {
      // && localErrors[key] == null
      if (serverCheckList[key] != null && checking[key] != null) {
        res[key] = true
      }
      return res
    }, {})

    // remove locally-only validated fields from check-list
    setChecking(val => {
      const newChecking = Object.keys(val).reduce((res, key) => {
        serverCheckList[key] != null && (res[key] = val[key])
        return res
      }, {})
      return newChecking
    })

    if (Object.keys(checkList).length > 0) {
      await getRemoteErrors({
        localErrors,
        newValues: values,
        checkList: serverCheckList,
      })
    }
  }

  if (needCheck.current) {
    needCheck.current = false
    validate()
  }

  const getEventData = event => {
    if (event && event.target) {
      return {
        target: event.target,
        value: event.target.value,
      }
    }
    return {
      target: null,
      value: event,
    }
  }

  const touch = (key) => {
    if (!touched[key]) {
      setTouched(val => ({
        ...val,
        [key]: true,
      }))
    }
  }

  const check = key => {
    if (!checking[key]) {
      setChecking(val => ({
        ...val,
        [key]: true,
      }))
    }

    needCheck.current = true
  }

  const form = mapValues(fields, (field, key) => {
    const hasError = touched[key] && errors[key] != null

    return {
      name: key,
      error: hasError,
      value: field.value,
      label: field.label,
      checking: checking[key],
      helperText: hasError
        ? getFirstLangString(...getErrorCode(key, errors[key]))
        : field.helperText,

      onBlur: (...args) => {
        const { value } = (typeof field.getEventData === 'function'
          ? field.getEventData
          : getEventData)(...args)

        !touched[key] && check(key)
        touch(key)
        
        if (key === 'regionaddress')
          check(key);//setTimeout(() => setErrors((errors) => errors[key] || values?.[key]?.valid ? errors : { ...errors, [key]: 'bad' }), 1000);
        
        typeof field.onBlur === 'function' && field.onBlur(value)
      },

      onChange: (...args) => {
        const { target, value } = (typeof field.getEventData === 'function'
          ? field.getEventData
          : getEventData)(...args)

        touch(key)

        let newValue = value

        if (target instanceof HTMLInputElement && field.ban instanceof RegExp) {
          newValue = value?.replace(field.ban, '') || '';

          if (value !== newValue) {
            restoreCursor.current = ((target, coord) => () => {
              target.setSelectionRange(coord - 1, coord - 1)
            })(target, target.selectionStart)
          } else {
            check(key)
          }
        } else {
          check(key)
        }

        typeof field.onChange === 'function' && field.onChange(newValue)
      },

      onChangeSave: (...args) => {
        const { value } = (typeof field.getEventData === 'function'
          ? field.getEventData
          : getEventData)(...args)

        touch(key)
        !touched[key] && check(key)
        typeof field.onChangeSave === 'function' && field.onChangeSave(value)
      },
    }
  })

  const checks = mapValues(fields, (field, key) => {
    if (checking[key]) {
      return renderProgress != null ? (
        renderProgress(progressProps)
      ) : (
        <ProgressComponent {...progressProps} />
      )
    }
  })

  const childProps = {
    form,
    checks,
    errors,
    touched,
    canSubmit,
    hasTouched,
    initialValues,
  }

  return render != null ? render(childProps) : <Component {...childProps} />
}

RawFormValidator.defaultProps = {
  hiddenFields: {},
  ProgressComponent: InputProgress,
}

RawFormValidator.propTypes = {
  // self props
  render: PropTypes.func,
  renderProgress: PropTypes.func,
  progressProps: PropTypes.object,
  Component: PropTypes.elementType,
  ProgressComponent: PropTypes.elementType,
  fields: PropTypes.object.isRequired,
  hiddenFields: PropTypes.object,
  setCanSubmit: PropTypes.func,
  setHasTouched: PropTypes.func,
  touchOnInit: PropTypes.bool,
  checkIfChanged: PropTypes.bool,
  method: PropTypes.string,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const FormValidator = withCatch(withLang(RawFormValidator))
