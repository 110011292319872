import React from 'react'
import PropTypes from 'prop-types'
import { noop as _noop } from 'lodash'
import { Formik, Form } from 'formik'

// @material-ui
import { withStyles } from '@material-ui/styles'

// core
import { FormBase } from '_core/components/FormBase'
import { ModalInner } from '_core/components/ModalInner'
import { ModalOuter } from '_core/components/ModalOuter'
import { Loading } from '_core/components/Loading'
import { Flex } from '_core/components/Flex'

const style = theme => ({})

const RawFormInModal = props => {
  const {
    isOpen,
    onClose,
    formRef,
    formikProps,
    formBaseProps,
    fixSubmitHandler,
    getSubmitHandler,
    modalInnerProps,
    modalOuterProps,
    renderActions,
    renderContent,
    readyProgress,
    ...rest
  } = props

  const getOnExited = ({ innerProps }) => () => {
    innerProps.resetForm()
    formikProps.onReset()
  }

  return (
    <FormBase
      {...formBaseProps}
      {...rest}
      render={outerProps => (
        <Formik
          {...formikProps}
          ref={formRef}
          render={innerProps => {
            const onExited = getOnExited({
              outerProps,
              innerProps,
            })

            const onSubmit =
              getSubmitHandler != null
                ? getSubmitHandler({ outerProps, innerProps })
                : fixSubmitHandler(innerProps)

            return (
              <ModalOuter
                open={isOpen}
                onClose={onClose}
                onExited={onExited}
                {...modalOuterProps}
              >
                {!formBaseProps.isReady ? (
                  readyProgress
                ) : (
                  <Form onSubmit={onSubmit}>
                    <ModalInner
                      {...modalInnerProps}
                      title={outerProps.renderTitle(innerProps)}
                      smallHeader
                      actions={
                        renderActions != null ? (
                          renderActions({ outerProps, innerProps })
                        ) : (
                          <Flex flexDirection="row-reverse">
                            {outerProps.renderSubmitButton(innerProps)}
                            {outerProps.renderResetButton(innerProps)}
                          </Flex>
                        )
                      }
                      content={
                        renderContent != null ? (
                          renderContent({ outerProps, innerProps })
                        ) : (
                          <>
                            {outerProps.renderArticle(innerProps)}
                            {outerProps.renderFields(innerProps)}
                            {outerProps.renderProgress(innerProps)}
                            {outerProps.renderSuccess(innerProps)}
                            {outerProps.renderFailure(innerProps)}
                          </>
                        )
                      }
                    />
                  </Form>
                )}
              </ModalOuter>
            )
          }}
        />
      )}
    />
  )
}

RawFormInModal.defaultProps = {
  isOpen: false,
  onClose: _noop,
  readyProgress: <Loading />,
}

RawFormInModal.propTypes = {
  formRef: PropTypes.any,
  formikProps: PropTypes.object.isRequired,
  formBaseProps: PropTypes.object.isRequired,
  fixSubmitHandler: PropTypes.func.isRequired,
  getSubmitHandler: PropTypes.func,
  modalInnerProps: PropTypes.object,
  modalOuterProps: PropTypes.object,
  readyProgress: PropTypes.node,
  renderActions: PropTypes.func,
  renderContent: PropTypes.func,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}

export const FormInModal = withStyles(style)(RawFormInModal)
