import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import clsx from 'clsx';
import { debounce } from 'lodash';
import { Cookies, withCookies } from 'react-cookie';

// @material-ui
import { withStyles } from '@material-ui/styles';

// core
import { GlobalContext } from '_core/hooks/useGlobal';
import { withContext } from '_core/hocs/withContext';
import { getStaticPath } from '_core/utils/getStaticPath';
import { encodeKey } from '_core/utils/storage';
import { Profile } from '_core/views/Profile';
import { OrderModal } from '_core/views/OrderModal';
import { ProductModal } from '_core/views/ProductModal';
import { PaymentModal } from '_core/views/PaymentModal';
import { ServiceModal } from '_core/views/ServiceModal';
import { RegisterModal } from '_core/views/RegisterModal';
import { VerificationSplash } from '_core/views/VerificationSplash';
import { Header } from '_core/components/Header';
import { UpwardButton } from '_core/components/UpwardButton';
import { GoodsCartModal } from '_core/components/GoodsCartModal';
import { CookieNotice } from '_core/components/CookieNotice';
import { DeliveryCityModal } from '_core/components/DeliveryCityModal';

import { Sidebar } from 'basic/views/Sidebar';

// style
import { clientStyle } from './clientStyle';


const RESIZE_DEBOUNCE_DELAY = 200;


class RawClient extends React.PureComponent {
  
  constructor(props) {
    super(props);

    const { cookies, config } = props;
    const { COOKIES_KEY } = config;

    this.state = {
      layoutProps: {},
      mobileOpen: false,
      isMini: cookies.get(encodeKey(COOKIES_KEY.SIDEBAR)) != null,
    };

    this._isMounted = false;
    this.mainPanel = React.createRef();

    this.setProfileTab = this.setProfileTab.bind(this);
    this.setSearchParam = this.setSearchParam.bind(this);
    this.setLayoutProps = this.setLayoutProps.bind(this);
    this.minimizeSidebar = this.minimizeSidebar.bind(this);
    this.onDrawerToggle = this.onDrawerToggle.bind(this);

    this._onResize = this._onResize.bind(this);
    this._debouncedOnResize = debounce(this._onResize, RESIZE_DEBOUNCE_DELAY);
  }

  componentDidMount() {
    this._isMounted = true;

    window.addEventListener('resize', this._debouncedOnResize);
  }

  componentWillUnmount() {
    this._isMounted = false;

    this._debouncedOnResize.cancel();
    window.removeEventListener('resize', this._debouncedOnResize);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.history.location.pathname !== prevProps.location.pathname) {
      this.mainPanel.current.scrollTop = 0;

      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }

  setLayoutProps(layoutProps) {
    this.setState({ layoutProps });
  }

  async minimizeSidebar() {
    const { cookies, config } = this.props;
    const { isMini } = this.state;
    const { COOKIES_TIME, COOKIES_KEY } = config;

    await this.setState({ isMini: !isMini });

    if (!isMini) {
      const stamp = Date.now() + COOKIES_TIME;

      cookies.set(encodeKey(COOKIES_KEY.SIDEBAR), 1, {
        path: '/',
        expires: new Date(stamp)
      });
    } else {
      cookies.remove(encodeKey(COOKIES_KEY.SIDEBAR), {
        path: '/',
        // expires: new Date(stamp),
      });
    }
  }

  onDrawerToggle() {
    this.setState(state => ({
      mobileOpen: !state.mobileOpen,
    }));
  }

  _onResize() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  }

  setProfileTab(tab) {
    const { location, history, config } = this.props;
    const { ROUTE_URL_PARAM, PROFILE_TAB_URL } = config;
    const search = new URLSearchParams(location.search);

    if (tab == null) {
      search.delete(ROUTE_URL_PARAM.PROFILE);
    } else {
      search.set(ROUTE_URL_PARAM.PROFILE, tab);
    }

    if (tab !== PROFILE_TAB_URL.VERIFICATION) {
      search.delete(ROUTE_URL_PARAM.VERIFY);
      search.delete(ROUTE_URL_PARAM.CODE);
    }

    location.search = search.toString();
    history.push(location);
  }

  setSearchParam(param, value) {
    const { location, history } = this.props;
    const search = new URLSearchParams(location.search);

    if (value == null) {
      search.delete(param);
    } else {
      search.set(param, value);
    }

    location.search = search.toString();
    history.push(location);
  }

  render() {
    const {
      classes,
      location,
      render,
      theme,
      config,
      cookies,
      getSlot,
    } = this.props;

    const { layoutProps, isMini, mobileOpen } = this.state;
    const { ROUTE_URL_PARAM, PROFILE_TAB_URL, COOKIES_KEY, HAS_CART, HAS_COOKIE } = config;
    const { title, menuId, slot, hideCart } = layoutProps;

    const search = new URLSearchParams(location.search);
    const profileTab = search.get(ROUTE_URL_PARAM.PROFILE);
    const paymentId = search.get(ROUTE_URL_PARAM.PAYMENT);
    const shopName = search.get(ROUTE_URL_PARAM.SHOP);
    const serviceId = search.get(ROUTE_URL_PARAM.SERVICE);
    const agreement = search.get(ROUTE_URL_PARAM.AGREEMENT);
    const hasRegister = !!search.get(ROUTE_URL_PARAM.REGISTER);
    

    const slotContent =
      slot != null
        ? getSlot(slot).map((item, index) => React.createElement(item, { key: index }))
        : null;

    const hasVerificationSplash =
      profileTab !== PROFILE_TAB_URL.VERIFICATION &&
      cookies.get(encodeKey(COOKIES_KEY.VERIFY)) == null;

    const mainClasses = clsx({
      [classes.content]: true,
      [classes.showCart]: HAS_CART && !hideCart,
    });

    return (
      <div className={classes.wrapper}>
        <Header
          title={title}
          isMini={isMini}
          hideCart={hideCart}
          toggleDrawer={this.onDrawerToggle}
          elevationProps={{ target: this.mainPanel.current }}
          onProfileOpen={() => this.setProfileTab(PROFILE_TAB_URL.INFO)}
        />

        <Sidebar
          logoText={process.env.REACT_APP_NAME}
          logo={getStaticPath(theme.pages.sidebar.logo)}
          menuId={menuId}
          isOpen={mobileOpen}
          isMini={isMini}
          minimizeSidebar={this.minimizeSidebar}
          toggleDrawer={this.onDrawerToggle}
          onProfileOpen={() => this.setProfileTab(PROFILE_TAB_URL.INFO)}
          hideCart={hideCart}
        />

        <main className={mainClasses} ref={this.mainPanel}>
          <div className={classes.headerHeight} />
          <div id="backToTop" ref={(element) => UpwardButton.scroll.anchor = element} />

          {slotContent}

          {render({
            setLayoutProps: this.setLayoutProps,
          })}
        </main>

        <Profile />
        <OrderModal />
        <ProductModal
          onClose={() => {
            this.setSearchParam(ROUTE_URL_PARAM.SHOP)
          }}
        />

        <ServiceModal
          service={serviceId}
          agreement={agreement}
          onClose={() => {
            search.delete(ROUTE_URL_PARAM.AGREEMENT)
            this.setSearchParam(ROUTE_URL_PARAM.SHOP)
            this.setSearchParam(ROUTE_URL_PARAM.SERVICE)
          }}
        />

        <PaymentModal
          product={paymentId}
          shop={shopName}
          onClose={() => {
            this.setSearchParam(ROUTE_URL_PARAM.SHOP);
            this.setSearchParam(ROUTE_URL_PARAM.PAYMENT);
          }}
        />

        <RegisterModal
          open={hasRegister}
          onClose={() => this.setSearchParam(ROUTE_URL_PARAM.REGISTER)}
        />

        {HAS_CART /*&& !hideCart*/ ? <GoodsCartModal /> : null}
        {hasVerificationSplash ? <VerificationSplash /> : null}
        
        <DeliveryCityModal />
        
        <UpwardButton target={this.mainPanel.current} />

        {HAS_COOKIE ? (
          <CookieNotice />
        ) : null}
      </div>
    );
  }
}

RawClient.propTypes = {
  // self props
  render: PropTypes.func,

  // `withCookies` HOC props
  cookies: PropTypes.instanceOf(Cookies),

  // `GlobalContext` props
  config: PropTypes.object.isRequired,
  request: PropTypes.object.isRequired,

  // `withStyles` HOC props
  theme: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
};

export const Client = withContext(GlobalContext)(
  withRouter(
    withCookies(withStyles(clientStyle, { withTheme: true })(RawClient))
  )
);
