export const shoppingGoodsItemStyle = theme => ({
  card: {
    // margin: '20px 0 0 0',
    // margin: 0,
    color: theme.palette.text.primary,
    height: `calc(100% - ${theme.spacing(3)}px)`,
  },

  cardMedia: {
    // position: 'relative',
    minHeight: '215px',
    // color: 'white',
    // display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'space-between',
    // '&::before': {
    //   content: "''",
    //   display: 'block',
    //   position: 'absolute',
    //   width: '100%',
    //   height: '100%',
    //   top: 0,
    //   left: 0,
    //   background:
    //     'linear-gradient(73.18deg, #687C86 1.66%, rgba(104, 124, 134, 0) 98.47%)',
    // },
  },

  cardContent: {
    position: 'relative',
  },

  cardText: {
    '& *:first-child': {
      marginTop: 0,
      paddingTop: 0,
    },
    '& *:last-child': {
      marginBottom: 0,
      paddingBottom: 0,
    },
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },

  cardActions: {
    // display: 'flex',
    // flexDirection: 'row-reverse',
    // flexWrap: 'wrap-reverse',
    // alignItems: 'flex-start',
    // paddingTop: 0,
  },

  headerBox: {
    position: 'relative',
    height: '100px',
  },

  fullWidth: {
    width: '100%',
  },

  halfBox: {
    width: '50%',
  },

  // infoButton: {
  //   marginRight: theme.spacing(2),
  // },

  // purchaseButton: {
  //   marginTop: theme.spacing(2),
  // },

  // card: {
  //   margin: '20px 0 0 0',
  //   color: theme.palette.text.primary,
  // },
  // cuted: {
  //   // boxShadow: 'none',
  // },
  // mainBg: {
  //   backgroundSize: 'cover',
  //   backgroundPosition: 'center',
  //   position: 'relative',
  //   overflow: 'hidden',
  //   minHeight: '150px',
  //   color: 'white',
  //   paddingTop: '25px',
  //   paddingBottom: '25px',
  //   '&::before': {
  //     content: "''",
  //     display: 'block',
  //     position: 'absolute',
  //     width: '100%',
  //     height: '100%',
  //     top: 0,
  //     left: 0,
  //     background:
  //       'linear-gradient(73.18deg, #687C86 1.66%, rgba(104, 124, 134, 0) 98.47%)',
  //   },
  // },
  // cutedBg: {
  //   borderRadius: '7px!important',
  // },
  // headerBox: {
  //   position: 'relative',
  //   height: '100px',
  // },

  // fullWidth: {
  //   width: '100%',
  // },
  // halfBox: {
  //   width: '50%',
  // },
  // value: {
  //   marginBottom: '5px',
  // },

  // button: {
  //   [theme.breakpoints.down('sm')]: {
  //     width: '100%',
  //   },
  // },

  // cardBody: {},
  // cardFooter: {},
  headerTitle: {
    position: 'relative',
  },
  headerDesciption: {
    position: 'relative',

    '& strong': {
      fontWeight: 'bold',
    },
  },
  promo: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      minHeight: 'unset',
    },

    '& $cardMedia': {
      '&::before': {
        [theme.breakpoints.up('md')]: {
          background: 'none',
        },
      },
      '&::after': {
        [theme.breakpoints.up('md')]: {
          content: "''",
          display: 'block',
          position: 'absolute',
          width: '200px',
          height: 'calc(100% + 2px)',
          right: '-1px',
          top: '-1px',
          // background: `url(${promoSmall})`,
          // transform: "translateX(calc(100% - 60px))",
          zIndex: 5,
          backgroundPosition: 'right',
          backgroundSize: 'auto 100%',
          backgroundRepeat: 'no-repeat',
        },
      },
      [theme.breakpoints.up('md')]: {
        width: '50%',
        position: 'relative',
        padding: '20px 50px',
        height: 'unset',
        backgroundPosition: 'right',
      },
    },
    '& $headerTitle': {
      [theme.breakpoints.up('md')]: {
        fontSize: '50px',
        lineHeight: 1,
      },
    },
    '& $headerDesciption': {
      [theme.breakpoints.up('md')]: {
        fontSize: '22px',
        lineHeight: '26px',
      },
    },

    '& $cardContent, & $cardActions, & $fullWidth, & $halfBox': {
      [theme.breakpoints.up('md')]: {
        width: 'unset',
        // flex: 'unset',
        padding: 0,
      },
    },
    // '& $halfBox': {
    //   [theme.breakpoints.up('md')]: {
    //     minWidth: '120px',
    //   },
    // },
    '& $halfBox:last-child': {
      [theme.breakpoints.up('md')]: {
        // marginRight: '40px',
      },
    },

    '& $cardContent': {
      [theme.breakpoints.up('md')]: {
        flexGrow: 2,
      },
    },
    '& $cardActions': {
      [theme.breakpoints.up('md')]: {
        flexGrow: 1,
        borderTop: 'none',
      },
    },
    '& $halfBox': {
      [theme.breakpoints.up('md')]: {
        flexGrow: 1,
      },
    },
  },
})
