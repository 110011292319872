import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// @material-ui
import { Typography, Card, CardMedia, CardContent } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

// core
import { useGlobal } from '_core/hooks/useGlobal'
import { withLang } from '_core/hocs/withLang'
import { withCatch } from '_core/hocs/withCatch'
import * as api from '_core/utils/api';
import { ArticleContainer } from '_core/components/ArticleContainer'
import { ContentBreadcrumbs } from '_core/components/ContentBreadcrumbs'
import { State } from '_core/components/State'
import { Title } from '_core/components/Title'
import Video from '_core/components/Video'

// style
import { articleStyle } from './articleStyle'

const RawArticle = props => {
  const {
    classes,
    id,
    path,
    hasDate,
    breadcrumbs,
    header,
    inCard,
    pathPrefix,
    render,
    renderEmpty,
    renderError,
    renderFailure,
    renderProgress,
    emptyMessage,
    failureMessage,
    stateProps,
    langInfo,
    getLangString: l,
  } = props


  const {
    config: {
      API_METHOD,
      DEFAULT_MESSAGE,
      API_ERROR: { DATA_WRONG, DATA_NOT_FOUND },
    },
  } = useGlobal()

  const [nodeKey, setNodeKey] = useState(0)
  const params = path != null ? { path } : { id }
  const getArticle = () => api.request(API_METHOD.ARTICLE_INFO, params)


  useEffect(() => {
    setNodeKey(nk => nk + 1)
  }, [langInfo])

  const renderHeader = article => (
    <>
      {hasDate ? (
        <Typography variant="caption" className={classes.date}>
          {l(article.time.date)}
        </Typography>
      ) : null}

      {article.title != null ? (
        <Title textProps={{ variant: 'h5' }} className={classes.title}>
          {article.title}
        </Title>
      ) : null}
    </>
  )

  const renderEmptyMessage = () =>
    renderEmpty != null ? (
      renderEmpty()
    ) : (
      <Typography align="center" variant="h6" color="textSecondary">
        {emptyMessage != null ? l(emptyMessage) : l('r.nichego.ne.najdeno')}
      </Typography>
    )

  const renderErrorMessage = error =>
    renderError != null ? (
      renderError(error)
    ) : (
      <Typography align="center" variant="h6" color="textSecondary">
        {failureMessage != null ? l(failureMessage) : l(DEFAULT_MESSAGE.ERROR)}
      </Typography>
    )

  return (
    <State
      {...stateProps}
      key={nodeKey}
      getProps={{ article: getArticle }}
      render={
        render != null
          ? render
          : ({ article }) => {
              return (
                <>
                  {breadcrumbs ? <ContentBreadcrumbs type="article" content={article} pathPrefix={pathPrefix} /> : null}
                  
                  {inCard ? (
                    <Card>
                      {header && article.photo_list?.length > 1 ? (
                        <CardMedia
                          className={classes.cardMedia}
                          image={article.photo_list.find((item) => item.id !== article.photo.id)?.path || article.photo.path}
                          title={l(article.title)}
                        >
                          {renderHeader(article)}
                        </CardMedia>
                      ) : null}
    
                      <CardContent className={classes.cardContent}>
                        {header && article.photo_list?.length > 1 ? null : renderHeader(article)}
                        
                        {article.description ? (
                          <Typography variant="h6" className={classes.description}>
                            {article.description}
                          </Typography>
                        ) : null}
                        
                        {article.video ? (
                          <Video src={article.video} autoplay />
                        ) : null}
                        
                        {article.content ? (
                          <ArticleContainer className={classes.content}>
                            {article.content}
                          </ArticleContainer>
                        ) : null}
                      </CardContent>
                    </Card>
                  ) : (
                    <>
                      {article.photo_list?.length > 1 ? null : renderHeader(article)}
                      
                      {article.description ? (
                        <Typography variant="h6" className={classes.description}>
                          {article.description}
                        </Typography>
                      ) : null}
                      
                      {article.video ? (
                        <Video src={article.video} autoplay />
                      ) : null}
                      
                      {article.content ? (
                        <ArticleContainer className={classes.content}>
                          {article.content}
                        </ArticleContainer>
                      ) : null}
                    </>
                  )}
                </>
              )
            }
      }
      renderFailure={
        renderFailure != null
          ? renderFailure
          : ({ error }) =>
              [DATA_WRONG, DATA_NOT_FOUND].includes(error.code)
                ? renderEmptyMessage()
                : renderErrorMessage(error)
      }
      renderProgress={renderProgress}
    />
  )
}

RawArticle.defaultProps = {
  hasDate: false,
  breadcrumbs: false,
  header: true,
  inCard: true,
  pathPrefix: ''
}

RawArticle.propTypes = {
  // self props
  id: PropTypes.number,
  path: PropTypes.string,
  hasDate: PropTypes.bool.isRequired,
  breadcrumbs: PropTypes.bool.isRequired,
  header: PropTypes.bool.isRequired,
  inCard: PropTypes.bool.isRequired,
  pathPrefix: PropTypes.string.isRequired,
  emptyMessage: PropTypes.string,
  failureMessage: PropTypes.string,
  render: PropTypes.func,
  renderEmpty: PropTypes.func,
  renderError: PropTypes.func,
  renderFailure: PropTypes.func,
  renderProgress: PropTypes.func,
  stateProps: PropTypes.object,

  // `withStyles` HOC props
  classes: PropTypes.object.isRequired,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  langStrings: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
}

export const Article = withCatch(withLang(withStyles(articleStyle)(RawArticle)))
