import React from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'

// @material-ui
import { withStyles } from '@material-ui/styles'

// core
import { FormBase } from '_core/components/FormBase'
import { Loading } from '_core/components/Loading'
import { Flex } from '_core/components/Flex'

const style = theme => ({})

const RawFormInPlain = props => {
  const {
    formRef,
    renderForm,
    renderFormBody,
    formikProps,
    formBaseProps,
    fixSubmitHandler,
    getSubmitHandler,
    readyProgress,
    ...rest
  } = props

  if (!formBaseProps.isReady) {
    return readyProgress
  }

  return (
    <FormBase
      {...formBaseProps}
      {...rest}
      render={(outerProps) => {
        return (
          <Formik
            {...formikProps}
            ref={formRef}
            render={(innerProps) => {
              const onSubmit =
                getSubmitHandler != null
                  ? getSubmitHandler({ outerProps, innerProps })
                  : fixSubmitHandler(innerProps)

              return renderForm != null ? (
                renderForm({ outerProps, innerProps })
              ) : (
                <Form onSubmit={onSubmit}>
                  {renderFormBody != null ? (
                    renderFormBody({ outerProps, innerProps })
                  ) : (
                    <>
                      {outerProps.renderArticle(innerProps)}
                      {outerProps.renderTitle(innerProps)}
                      {outerProps.renderFields(innerProps)}
                      {outerProps.renderProgress(innerProps)}
                      {outerProps.renderSuccess(innerProps)}
                      {outerProps.renderFailure(innerProps)}

                      <Flex flexDirection="row-reverse">
                        {outerProps.renderSubmitButton(innerProps)}
                        {outerProps.renderResetButton(innerProps)}
                      </Flex>
                    </>
                  )}
                </Form>
              )
            }}
          />
        )
      }}
    />
  )
}

RawFormInPlain.defaultProps = {
  readyProgress: <Loading />,
}

RawFormInPlain.propTypes = {
  formRef: PropTypes.any,
  renderForm: PropTypes.func,
  renderFormBody: PropTypes.func,
  formikProps: PropTypes.object.isRequired,
  formBaseProps: PropTypes.object.isRequired,
  fixSubmitHandler: PropTypes.func.isRequired,
  getSubmitHandler: PropTypes.func,
  readyProgress: PropTypes.node,
}

export const FormInPlain = withStyles(style)(RawFormInPlain)
