import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// @material-ui
import { IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { AccountBalanceWallet } from '@material-ui/icons';

// core
import { getMoneyAccountList, getMoneyAccountBalance } from '_core/utils/api';
import { withVisitorInfo } from '_core/utils/visitorInfo';
import { useGlobal } from '_core/hooks/useGlobal';
import { withLang } from '_core/hocs/withLang';
import { useCartContext } from '_core/contexts/Cart';
import { Menu } from '_core/components/Menu';
import { Loading } from '_core/components/Loading';
import { BalanceButton } from '_core/components/BalanceButton';
import { BalanceDetails } from '_core/components/BalanceDetails';
import { State } from '_core/components/State';

// style
import { balancePopupStyle } from './balancePopupStyle';


const RawBalancePopup = (props) => {
  const {
    classes,
    inMenu,
    disabled,
    tooltip,
    hasValues,
    visitorInfo,
    langInfo,
    getLangString: l
  } = props;

  const rootClasses = clsx({
    [classes.root]: true,
    [classes.inMenu]: inMenu,
    [classes.hasValue]: hasValues,
  });

  const progressClasses = clsx({
    [classes.progress]: true,
    [classes.hasValue]: hasValues,
  });

  const buttonRootClasses = clsx({
    [classes.button]: true,
    [classes.buttonContrast]: inMenu,
  });

  const cart = useCartContext();

  const {
    config: { API_NOTICE },
  } = useGlobal();

  const [nodeKey, setNodeKey] = useState(0);

  React.useEffect(() => {
    setNodeKey(nk => nk + 1)
  }, [visitorInfo.info?.id, langInfo, cart.currency]);

  React.useEffect(() => {
    const notifyListener = (event) => event.detail.name === API_NOTICE.VISITOR_BALANCE && setNodeKey(nk => nk + 1);
    
    window.addEventListener('notify', notifyListener);
    
    return () => window.removeEventListener('notify', notifyListener);
  }, []);

  const getButtonProps = ({ accounts, accountTypes }) => {
    const showAccounts = accounts.filter((item) => visitorInfo.inGroup(item.header_visitor_group));
    
    const hasBlocked = showAccounts.some((item) => item.blocked.value > 0);
    
    return hasValues
      ? {
          renderButton: ({ onClick, active }) => (
            <BalanceButton
              accounts={showAccounts}
              accountTypes={accountTypes}
              hasBlocked={hasBlocked}
              disabled={disabled}
              onClick={onClick}
              active={active}
              inMenu={inMenu}
            />
          ),
        }
      : {
          buttonBody: <AccountBalanceWallet />,
          ButtonComponent: IconButton,
          buttonProps: {
            disabled,
            classes: {
              root: buttonRootClasses,
              label: classes.label,
            },
          },
        };
  };

  return (
    <State
      key={nodeKey}
      getProps={{
        accounts: () => getMoneyAccountBalance(visitorInfo.info?.id).then((list) => list.filter((account) => account.is_content)),
        accountTypes: getMoneyAccountList
      }}
      animationProps={{
        classes: {
          container: rootClasses,
          wrapper: classes.wrapper,
        },
      }}
      render={({ accounts, accountTypes }) => (
        <Menu
          {...getButtonProps({ accounts, accountTypes })}
          content={
            <BalanceDetails
              accounts={accounts}
              accountTypes={accountTypes}
            />
          }
          tooltip={l(tooltip)}
          paperProps={{ className: classes.paper }}
          popoverProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          }}
        />
      )}
      renderProgress={() => (
        <div className={rootClasses}>
          <Loading className={progressClasses} />
        </div>
      )}
    />
  );
};

RawBalancePopup.displayName = 'RawBalancePopup';

RawBalancePopup.defaultProps = {};

RawBalancePopup.propTypes = {
  // self props
  inMenu: PropTypes.bool,
  hasValues: PropTypes.bool,
  tooltip: PropTypes.string,

  // withVisitorInfo
  visitorInfo: PropTypes.object.isRequired,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired,
};

export const BalancePopup = withVisitorInfo(withLang(
  withStyles(balancePopupStyle, { name: 'RtsBalancePopup' })(RawBalancePopup)
));
