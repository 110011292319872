// @material-ui
import { makeStyles } from '@material-ui/styles'

export const style = (theme) => ({
  breadcrumbsLine: {
    padding: '0px 5px 30px 5px',
    '& li': {
      lineHeight: 1,
      '& .MuiSvgIcon-fontSizeSmall': {
        fontSize: '1rem',
      },
    },
  },
})

export const useStyle = makeStyles(style)
