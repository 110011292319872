//import React from 'react';
import PropTypes from 'prop-types';

import * as convert from '_core/utils/convert';
//import * as langs from 'utils/langs';
import { timeout } from '_core/utils/async';
import * as cache from '_core/utils/cache';
import * as http from '_core/utils/http';

import { API_SERVER, API_REST_URI, API_REQUEST_URI, API_UPLOAD_URI, API_LISTEN_URI, API_LISTEN_REQUIRED, API_LISTEN_INTERVALS } from 'config';


const METHODS_PROPS = {
  'client/visitor/authorize': { requestCacheInterval: 100 },
  'client/visitor/info': { requestCacheInterval: 100 },
  'client/visitor/logout': { requestCacheInterval: 100 }
};


const apiServer = convert.toString(API_SERVER).replace(/\/$/, '');

let apiListenStart = false;


export class ApiError extends Error {
  
  constructor(code, message, data, options) {
    super(message, options || {});
    
    if (!code)
      code = 'system-error';
    
    this.code = code;
    this.data = data;
    
    if (data && Object.keys(data).length) {
      console.warn(code, '-', message, ':', data);
    } else {
      console.warn(code, '-', message);
    }
  }
  
}

export const propTypes = {
  requestServer: PropTypes.string.isRequired,
  requestDelay: PropTypes.number.isRequired,
  
  attemptCount: PropTypes.number.isRequired,
  attemptInterval: PropTypes.number.isRequired,
  
  expectedCode: PropTypes.string
};

export const defaultProps = {
  requestServer: apiServer,
  requestDelay: 0,
  requestCacheInterval: 5000,
  requestFromServer: false,
  
  attemptCount: 10,
  attemptInterval: 2000,
  
  expectedCode: 'ok'
};

export const path = (uri) => {
  if (!uri)
    return null;
  
  if (typeof uri === 'object') {
    if (uri.id === 0)
      return null;
    
    uri = uri.path;
  }
  
  if (uri[0] !== '/')
    uri = '/' + uri;
  
  return apiServer + uri;
};

export const rest = async (type, method, data, props) => {
  props = buildProps(defaultProps, props);
  
  type = convert.toString(type).toUpperCase();
  
  let url = props.requestServer + API_REST_URI + method;
  
  const param = {
    method: type,
    mode: 'cors',
    headers: {}
  };
  
  if (type === 'GET') {
    if (data)
      url = http.param(url, data);
  } else {
    param.headers['Content-Type'] = 'application/json; charset=UTF-8';
    param.body = JSON.stringify(data);
  }
  
  if (props.requestFromServer) {
    if (typeof Window !== 'undefined')
      throw new ApiError(502, 'Requests from the website are not allowed, url: "' + url + '"', null);
  } else {
    if (typeof Window === 'undefined')
      throw new ApiError(502, 'Requests from the server are not allowed, url: "' + url + '"', null);
  }
  
  if (props.requestDelay)
    await timeout(props.requestDelay);
  
  if (param.mode === 'cors' && props.requestServer)
    param.credentials = 'include';
  
  let resp;
  
  for (let count = props.attemptCount - 1; ; count--) {
    try {
      resp = await fetch(url, param);
      
      if (resp.status >= 500) {
        if (!count)
          throw new ApiError(resp.status, resp.statusText, null);
        
        await timeout(props.attemptInterval);
        
        continue;
      }
      
      break;
    } catch (e) {
      if (!count) {
        if (e instanceof ApiError)
          throw e;
        
        throw new ApiError(502, 'Error while fetching data at "' + url + '": ' + e.message, null, { cause: e });
      }
      
      await timeout(props.attemptInterval);
    }
  }
  
  let json;
  
  try {
    json = await resp.json();
  } catch (e) {
    throw new ApiError(502, 'Invalid JSON format in response: ' + e.message, null, { cause: e });
  }
  
  return json;
  
};

export const request = async (method, param, props) => {
  const fetchRequest = { method, param: param || {} };
  
  if (props?.lang)
    fetchRequest.lang = props.lang.id || props.lang;
  
  const fetchParam = {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    },
    
    body: 'data=' + encodeURIComponent(JSON.stringify(fetchRequest))
  };
  
  const cache = request.cache || (request.cache = {});
  
  if (cache[method]?.body !== fetchParam.body || cache[method]?.iteration !== props?.iteration) {
    if (cache[method])
      clearTimeout(cache[method].timeout);
    
    cache[method] = {
      method,
      body: fetchParam.body, 
      promise: fetchData(API_REQUEST_URI, fetchParam, props),
      timeout: setTimeout(() => delete cache[method], props?.requestCacheInterval || METHODS_PROPS[method] || defaultProps.requestCacheInterval),
      iteration: props?.iteration
    };
  }
  
  listen(props);
  
  return (await cache[method].promise).data;
};

export const upload = async (files, param, props) => {
  if (!Array.isArray(files))
    files = [files];
  
  if (!param)
    param = {};
  
  if (!param.category)
    param.category = 'ticket';
  
  const fetchParam = {
    method: 'POST',
    mode: 'cors',
    body: new FormData()
  };
  
  files.forEach((file) => fetchParam.body.append('file', file));
  
  Object.entries(param).forEach(([key, value]) => Array.isArray(value) ? value.forEach((item) => fetchParam.body.append(key, item)) : fetchParam.body.append(key, value));
  
  return (await fetchData(API_UPLOAD_URI, fetchParam, props)).list;
};

export const listen = (props) => {
  if (apiListenStart || !API_LISTEN_REQUIRED || typeof window === 'undefined')
    return;
  
  apiListenStart = true;
  
  const fetchParam = {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache'
  };
  
  const fetchProps = {
    ...props,
    
    attemptCount: 1,
    expectedCode: null
  };
  
  let fetchLast = 0;
  let fetchTimeout = null;
  let activeLast = Date.now();
  let fetchIsFirst = true;
  
  const fetchFunc = () => {
    fetchTimeout = null;
    
    if (Date.now() - activeLast > API_LISTEN_INTERVALS.inactiveTimeout)
      activeLast = 0;
    
    fetchData(API_LISTEN_URI + '?timestamp=' + encodeURIComponent(fetchLast), fetchParam, fetchProps)
      .then((data) => {
        const count = data.notifications?.length;
        
        if (count) {
          fetchTimeout = setTimeout(fetchFunc, activeLast ? API_LISTEN_INTERVALS.activeAccel : API_LISTEN_INTERVALS.inactiveAccel);
        } else {
          fetchTimeout = setTimeout(fetchFunc, activeLast ? API_LISTEN_INTERVALS.active : API_LISTEN_INTERVALS.inactive);
        }
        
        const last = fetchLast;
        
        if (data.last_timestamp)
          fetchLast = data.last_timestamp;
        
        if (fetchIsFirst) {
          fetchIsFirst = false;
          
          return;
        }
        
        for (var n = 0; n < count; n++)
          notify(data.notifications[n]?.name, data.notifications[n]?.data);
        
        activeLast = Date.now();
      })
      .catch((error) => fetchTimeout = setTimeout(fetchFunc, activeLast ? API_LISTEN_INTERVALS.activeError : API_LISTEN_INTERVALS.inactiveError));
  };
  
  const activeFunc = () => {
    if (!activeLast && fetchTimeout) {
      clearTimeout(fetchTimeout);
      
      fetchTimeout = setTimeout(fetchFunc, API_LISTEN_INTERVALS.active);
    }
    
    activeLast = Date.now();
  };
  
  fetchTimeout = setTimeout(fetchFunc, API_LISTEN_INTERVALS.first);
  
  window.addEventListener('focus', activeFunc);
  window.addEventListener('click', activeFunc);
  window.addEventListener('mousemove', activeFunc);
  window.addEventListener('keydown', activeFunc);
};

export const notify = (name, data) => name && typeof window !== 'undefined' ? window.dispatchEvent(new CustomEvent('notify', { detail: { name, data: data || {} } })) : null;

export const getClientVisitorStatusTypes = () => getList(['client/visitor/status/types'], null);

export const useClientVisitorStatusTypes = (onSuccess) => useList(['client/visitor/status/types'], null, onSuccess);

export const useMoneyCurrencyList = (visitorId, onSuccess) => useData(['money/currency/list', visitorId], null, onSuccess);

export const getMoneyCurrencyRate = (srcCode, dstCode) => cache.get(null, ['get', 'money/currency/rate', srcCode, dstCode], (context) => rest('get', 'money/currency/rate/' + srcCode + '-' + dstCode).then((data) => data[dstCode]), 30000);

export const getMoneyAccountList = () => getList(['money/account/list'], null);

export const getMoneyAccountBalance = (visitorId) => getList(['money/account/balance', visitorId], null, 5 * 60000);

export const clearMoneyAccountBalance = () => cache.clear(null, 'money/account/balance');

export const getMoneyAccountTransmitDirections = () => getList(['money/account/transmit/directions'], null);

export const getOfficeSettingsValues = () => getData(['office/settings/values'], null);

export const getOfficeSettingsValue = async (name, defaultValue) => {
  try {
    const result = (await getOfficeSettingsValues())[name];
    
    if (typeof result === 'undefined' || result === null)
      return typeof defaultValue === 'undefined' ? result : defaultValue;
    
    return result;
  } catch (e) {
    if (typeof defaultValue === 'undefined')
      throw e;
    
    return defaultValue;
  }
};

export const getOurclubVisitorMatrixZonePrograms = () => getList(['ourclub/visitor/matrix_zone/programs'], null);


const formCache = {};

const buildProps = (defaultProps, props) => {
  if (!props || typeof props !== 'object')
    return defaultProps;
  
  props = { ...defaultProps, ...props };
  
  PropTypes.checkPropTypes(propTypes, props, 'prop', 'API');
  
  return props;
};

/*const prepareErrors = async (lang, codes) => {
  if (!codes)
    return;
  
  let errors = null;
  
  for (const [key, error] of Object.entries(codes)) {
    if (!errors)
      errors = {};
    
    errors[key] = {
      code: error.code,
      message: langs.lineOr(lang, error.message, error.default_message)
    };
  }
  
  if (!errors)
    return;
  
  for (const error of Object.values(errors))
    error.message = await error.message;
  
  //console.log(errors);
  
  throw new ApiError('data-incorrect', 'Data incorrect', { errors });
};*/

const fetchData = async (uri, param, props) => {
  props = buildProps(defaultProps, props);
  
  if (props.requestFromServer) {
    if (typeof Window !== 'undefined')
      throw new ApiError('network-error', 'Requests from the website are not allowed, uri: "' + uri + '"', null);
  } else {
    if (typeof Window === 'undefined')
      throw new ApiError('network-error', 'Requests from the server are not allowed, uri: "' + uri + '"', null);
  }
  
  let resp;
  
  if (props.requestDelay)
    await timeout(props.requestDelay);
  
  if (param.mode === 'cors' && props.requestServer)
    param.credentials = 'include';
  
  for (let count = props.attemptCount - 1; ; count--) {
    try {
      resp = await fetch(props.requestServer + uri, param);
      
      if (resp.status !== 200) {
        if (!count)
          throw new ApiError('server-error', resp.status + ' ' + resp.statusText, null);
        
        await timeout(props.attemptInterval);
        
        continue;
      }
      
      break;
    } catch (e) {
      if (!count) {
        if (e instanceof ApiError)
          throw e;
        
        throw new ApiError('network-error', 'Error while fetching data at "' + uri + '": ' + e.message, null, { cause: e });
      }
      
      await timeout(props.attemptInterval);
    }
  }
  
  let json;
  
  try {
    json = await resp.json();
  } catch (e) {
    throw new ApiError('server-error', 'Invalid JSON format in response: ' + e.message);
  }
  
  if (props.expectedCode && json.code !== props.expectedCode) {
    //if (json.code === 'data-incorrect')
    //  await prepareErrors(props.lang, json.data?.errors);
    
    throw new ApiError(json.code, json.message, json.data);
  }
  
  return json;
};

const getData = (key, param, clearMillis) => cache.get(
    null,
    key,
    (context) => request(key[0], param),
    clearMillis
  );

const getList = (key, param, clearMillis) => cache.get(
    null,
    key,
    (context) => request(key[0], param).then((data) => data.list || []),
    clearMillis
  );

const useData = (key, param, onComplete, clearMillis) => cache.use(
    null,
    key,
    (context) => request(key[0], param),
    onComplete,
    clearMillis
  );

const useList = (key, param, onComplete, clearMillis) => cache.use(
    null,
    key,
    (context) => request(key[0], param).then((data) => data.list || []),
    onComplete,
    clearMillis
  );

if (typeof window !== 'undefined')
  window.addEventListener('notify', (event) => event.detail.name === 'money/visitor/balance' && clearMoneyAccountBalance());
