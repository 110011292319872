//import React from 'react';

import BigNumber from 'bignumber.js';

import * as math from './math';


const BIGNUMBER_ZERO = BigNumber(0);

const BIGNUMBER_ONE = BigNumber(1);

const BIGNUMBER_HUNDRED = BigNumber(100);

const BIGNUMBER_HUNDREDTH = BigNumber(0.01);


export { BIGNUMBER_ZERO, BIGNUMBER_ONE, BIGNUMBER_HUNDRED, BIGNUMBER_HUNDREDTH };

export const toFloat = (value, defaultValue = 0) => {
  if (math.isNone(value))
    return defaultValue;
  
  if (typeof value === 'object')
    value = value.valueOf();
  
  const type = typeof value;
  
  if (type === 'boolean')
    return defaultValue;
  
  if (type !== 'number')
    value = Number(value);
  
  if (Number.isNaN(value))
    return defaultValue;
  
  return value;
};

export const toUFloat = (value, defaultValue = 0) => Math.max(toFloat(value, defaultValue), 0);

export const toInt = (value, defaultValue = 0) => Math.round(toFloat(value, defaultValue));

export const toUInt = (value, defaultValue = 0) => Math.round(toUFloat(value, defaultValue));

export const toBigNumber = (value, defaultValue = BIGNUMBER_ZERO) => {
  if (math.isNone(value))
    return defaultValue;
  
  if (BigNumber.isBigNumber(value))
    return value;
  
  if (typeof value === 'object')
    value = value.valueOf();
  
  const type = typeof value;
  
  if (type === 'boolean')
    return defaultValue;
  
  value = BigNumber(value);
  
  if (value.isNaN())
    return defaultValue;
  
  return value;
};

export const toString = (value, defaultValue = '') => {
  if (math.isNone(value))
    return defaultValue;
  
  if (typeof value === 'object')
    value = value.valueOf();
  
  const type = typeof value;
  
  if (type !== 'string')
    value = String(value);
  
  return value;
};

export const toUTF8Array = (value) => {
  const result = [];
  
  value = toString(value);
  
  for (let i = 0, length = value.length; i < length; i++) {
    let charcode = value.charCodeAt(i);
    
    if (charcode < 0x80) {
      result.push(charcode);
    } else if (charcode < 0x800) {
      result.push(0xc0 | (charcode >> 6), 0x80 | (charcode & 0x3f));
    } else if (charcode < 0xd800 || charcode >= 0xe000) {
      result.push(0xe0 | (charcode >> 12), 0x80 | ((charcode >> 6) & 0x3f), 0x80 | (charcode & 0x3f));
    } else { // surrogate pair
      i++;
      // UTF-16 encodes 0x10000-0x10FFFF by subtracting 0x10000 and splitting the 20 bits of 0x0-0xFFFFF into two halves
      charcode = 0x10000 + (((charcode & 0x3ff) << 10) | (value.charCodeAt(i) & 0x3ff));
      
      result.push(0xf0 | (charcode >> 18), 0x80 | ((charcode >> 12) & 0x3f), 0x80 | ((charcode >> 6) & 0x3f), 0x80 | (charcode & 0x3f));
    }
  }
  
  return result;
}
