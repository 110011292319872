import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// @material-ui
import { Breadcrumbs, Link as MuiLink, Typography } from '@material-ui/core';
import { NavigateNext } from '@material-ui/icons';

// core
import { useGlobal } from '_core/hooks/useGlobal';
import { withLang } from '_core/hocs/withLang';

// style
import { useStyle } from './style';


export const RawContentBreadcrumbs = (props) => {
  const {
    type,
    content,
    baseUrl,
    pathPrefix,
    getLangString: l
  } = props;

  const {
    config: { ROUTE_URL },
  } = useGlobal()

  const classes = useStyle();

  const path = [{ link: ROUTE_URL.INDEX, title: l('r._.menu.item.dashboard') }];

  if (type === 'goods' || type === 'goods-category')
    path.push({ link: ROUTE_URL.CATALOG, title: l('r._.menu.item.goods') });

  if (content) {
    let link = baseUrl;
    
    if (content.path) {
      let realLink = '';
      
      const pathIgnore = pathPrefix.split('/').reduce((result, item) => result.length ? result[result.length - 1] + '/' + item : item, []);
      
      content.path.forEach((item) => {
        if (realLink.length)
          realLink += '/';
        
        realLink += item.name;
        
        if (pathIgnore.indexOf(realLink) >= 0)
          return;
        
        link += '/' + item.name;
        
        path.push({ link: link, title: item.title });
      });
    }
    
    path.push({ link: link + '/' + content.name, title: content.title });
  }

  return (
    <Breadcrumbs className={classes.breadcrumbsLine} separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
      {path.map((item, index) => index < path.length - 1 
        ? (<MuiLink variant="body2" underline="hover" color="inherit" to={item.link} component={Link}>{item.title}</MuiLink>) 
        : (<Typography color="text.primary" variant="body2">{item.title}</Typography>))}
    </Breadcrumbs>
  );
};

RawContentBreadcrumbs.defaultProps = {
  baseUrl: '',
  pathPrefix: ''
};

RawContentBreadcrumbs.propTypes = {
  // self props
  type: PropTypes.string.isRequired,
  baseUrl: PropTypes.string.isRequired,
  pathPrefix: PropTypes.string.isRequired,

  // `withLang` HOC props
  langInfo: PropTypes.object,
  getLangObject: PropTypes.func,
  getFirstLangString: PropTypes.func,
  getLangStringSet: PropTypes.func,
  getLangString: PropTypes.func.isRequired
};

export const ContentBreadcrumbs = withLang(RawContentBreadcrumbs);
