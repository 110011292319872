import { shoppingGoodsItemStyle } from '_core/components/ShoppingGoodsItem/shoppingGoodsItemStyle'

export const articleStyle = theme => {
  const style = shoppingGoodsItemStyle(theme)

  return {
    cardMedia: style.cardMedia,
    cardContent: style.cardContent,

    date: {
      textAlign: 'right',
    },

    title: {
      margin: theme.spacing(0, 0, 2),
    },

    description: {
      margin: theme.spacing(2, 0),
    },

    content: {
      marginTop: theme.spacing(2),
    },
    
    linking: {
      justifyContent: 'space-between',
      flexDirection: 'row-reverse',
    },
  }
}
